<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-10">
                <h2>Warengruppen</h2>
            </div>
            <div class="text-right col-md-2">
                <button class="btn btn-sm btn-primary" @click="newGroup">Neue Warengruppe</button>
            </div>
        </div>
        <div class="mt-2 row">
            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            Warengruppenbaum
                        </div>
                    </div>

                    <div class="card-header">
                        <Tree :value="groupsTree" @change="saveOrderWithConfirmation" foldAllAfterMounted>
                            <!-- eslint-disable-next-line -->
                            <span slot-scope="{node, index, path, tree}">
                                <i class="fas fa-folder-plus" @click="tree.toggleFold(node, path)" v-if="node.$folded"></i>
                                <i class="fas fa-folder-minus" @click="tree.toggleFold(node, path)" v-if="!node.$folded"></i>
                                
                                <div class="input-group" v-if="selectedRow[node.id] && selectedCell === 'name'">
                                    <input type="text" class="form-control form-control-sm" v-model="groups.find(item => item.id == node.id).name" />
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-primary" type="button" @click="changeName(node.id)"><i class="fas fa-save"></i></button>
                                        <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(node.id, 'name')"><i class="fas fa-times"></i></button>
                                    </div>
                                </div>
                                <a v-else class="ml-1" @dblclick="handleEditCell(node.id, 'name')" @click="selectGroup(node.id)">{{node.name}}</a>
                            </span>
                        </Tree>
                    </div>

                    <div class="card-footer">

                    </div>
                </div>
            </div>

            <div class="col-md-9">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            Warengruppen Einstellungen
                        </div>
                    </div>

                    <div class="card-body">
                        <div v-if="selectedGroup == null">
                            <h6 class="text-danger">Bitte wähle eine Warengruppe aus, um die Einstellungen zu öffnen</h6>
                        </div>

                        <div v-if="selectedGroup != null">

                            <div class="form-group">
                                <label for="name" class="control-label">Name</label>
                                <input v-model="selectedGroup.name" type="text" class="form-control form-control-sm">
                            </div>

                            <div class="form-group">
                                <label for="cms_title" class="control-label">Titel (CMS)</label>
                                <input v-model="selectedGroup.cms_title" type="text" class="form-control form-control-sm">
                            </div>

                            <div class="form-group">
                                <label for="cms_description" class="control-label">Beschreibung (CMS)</label>
                                <textarea rows="5" v-model="selectedGroup.cms_description" class="form-control form-control-sm"></textarea>                   
                            </div>

                            <div class="form-group">
                                <button class="btn btn-primary btn-sm" @click="updateGroup(selectedGroup)">Speichern</button>                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>

</template>

<script>
import 'he-tree-vue/dist/he-tree-vue.css'
import {Tree, Fold, Draggable} from 'he-tree-vue'

export default {
    components: {Tree: Tree.mixPlugins([Fold, Draggable])},
    data() {
        return {
            groups: [],
            groupsTree: [],
            selectedRow: {},
            selectedCell: null,
            selectedGroup: null,
        }
    },

    methods: {
        selectGroup(id){
            var group = this.groups.find(item => item.id == id);
            this.selectedGroup = group;
        },

        updateGroup(group){
            this.axios
                .put("/groups/" + group.id, group)
                .then(() => {
                    this.loadGroupTree();
                    this.loadGroups();
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
        
        changeName(id){
            var group = this.groups.find(item => item.id == id);
            this.axios
                .put("/groups/" + group.id, {
                    'name': group.name
                })
                .then(() => {
                    this.handleEditCell(group.id, 'name');
                    this.loadGroupTree();
                    this.loadGroups();
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        handleEditCell(index, name) {
            this.selectedCell = name;
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },

        newGroup(){
            this.axios
                .post("/groups", {
                    'name': 'Neue Warengruppe ' +  Math.floor(Math.random() * 11)
                })
                .then(() => {
                    this.loadGroupTree();
                    this.loadGroups();
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        saveOrderWithConfirmation(){
            this.$swal({
                    title: "Möchtest du die Reihenfolge der Warengruppen wirklich ändern?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.saveOrder()
                    }
                    if (!result.isConfirmed) {
                        this.loadGroupTree();
                        this.loadGroups();
                    }
                });
        },

        
        saveOrder() {
            this.axios
                .post("/groups/saveOrder", {data: this.groupsTree} )
                .then(() => {
                    this.loadGroupTree();
                    this.loadGroups();
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadGroups() {
            this.axios
                .get("/groups")
                .then((response) => {
                    this.groups = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadGroupTree() {
            this.axios
                .get("/groups/tree")
                .then((response) => {
                    this.groupsTree = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
    },

    created() {
        this.loadGroups();
        this.loadGroupTree();
    },

    mounted(){
        
    }
}
</script>

<style>

</style>